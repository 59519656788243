<template>
  <canvas ref="area" />
</template>

<script>
export default {
  name: 'FramesWork',
  props: {
    id: String,
    img: String,
    artist: String,
    title: String,
    coordinates: Array,
    heightWrap: String,
    autoWidth: Boolean,
    info: Object,
  },
  data() {
    return {
      test: '',
      width: 1,
      height: 1,
      nX: 1,
      nY: 1,
      contextPointer: undefined,
      contextImage: undefined,
    };
  },
  methods: {
    drawCellRect(x, y) {
      // Por ahora estoy borrando todo y dibujando de nuevo el canvas
      // Hay que superponer los dos en el mismo sitio
      // Asi solo hace falta borrar el canvas contextPointer (comentado arriba)
      // Y pintar el frame
      this.contextImage.save();
      this.contextImage.clearRect(
        0,
        0,
        this.contextImage.canvas.clientWidth,
        this.contextImage.canvas.clientHeight
      );

      this.contextImage.drawImage(this.image, 0, 0, this.width, this.height);

      this.contextImage.beginPath();
      this.contextImage.strokeStyle = 'lime';
      this.contextImage.lineWidth = 10;
      this.contextImage.arc(
        (x - 0.5) * this.nX + this.pL,
        (y - 0.5) * this.nY + this.pT,
        50,
        0,
        2 * Math.PI
      );
      this.contextImage.stroke();

      this.contextImage.beginPath();
      this.contextImage.strokeStyle = 'yellow';
      this.contextImage.lineWidth = 5;
      const leftCornerX = x * this.nX - this.nX + this.pL;
      const rightCornerX = y * this.nY - this.nY + this.pT;
      this.contextImage.rect(leftCornerX, rightCornerX, this.nX, this.nY);
      this.contextImage.stroke();
    },
  },
  mounted() {
    this.image = new Image();
    this.image.src = this.img;
    // this.contextPointer = this.$refs.frame.getContext('2d', { alpha: false });

    this.image.onload = function () {
      this.$refs.area.width = this.image.width;
      this.$refs.area.height = this.image.height;
      const resize = this.info.imgHeight / this.image.height;

      this.nX = this.info.nX / resize;
      this.nY = this.info.nY / resize;
      this.pL = this.info.pL / resize;
      this.pT = this.info.pT / resize;
      this.ratio = this.image.width / this.image.height;
      this.height = this.image.height;
      this.width = this.height * this.ratio;
      this.$refs.area.height = this.height;
      this.$refs.area.width = this.width;

      this.contextImage = this.$refs.area.getContext('2d', { alpha: false });
      this.contextImage.lineWidth = 10;
      this.contextImage.strokeStyle = 'lime';
      this.contextImage.drawImage(
        this.image,
        0,
        0,
        this.$refs.area.width,
        this.$refs.area.height
      );
      this.drawCellRect(this.coordinates[0], this.coordinates[1]);
    }.bind(this);
  },
  watch: {
    coordinates: function (val) {
      if (this.contextImage) this.drawCellRect(val[0], val[1]);
    },
  },
};
</script>
